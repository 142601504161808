import * as React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useAccount } from 'wagmi';
import { getUser, approve, allowance, becomeUser, withdrawStablecoinAndEther } from '../../utils/contractInteractions';

export default function Actions() {
  const { address } = useAccount();

  const [investDisabled, setInvestDisabled] = React.useState(false);
  const [ user, setUser ] = React.useState({});
  const [ stablecoinAllowance, setStablecoinAllowance ] = React.useState(0);
  const [ weeklyAmount, setWeeklyAmount ] = React.useState(0);
  const [ investStablecoin, setInvestStablecoin ] = React.useState(0);
  const [ divestEther, setDivestEther ] = React.useState(0);
  const [ divestStablecoin, setDivestStablecoin ] = React.useState(0);

  React.useEffect(() => {
      allowance(address).then((allowance) => (setStablecoinAllowance(allowance)));
  }, []);

  React.useEffect(() => {
    if (address) {
      getUser(address).then((user) => {
        setUser(user);
        const investment = parseInt(user.weeklyInvestment);
        if (investment > 0) {
          setWeeklyAmount(investment);
        }
      });
    }
  }, [address]);

  const handleMissingWallet = () => {
    alert('Please connect your wallet');
  };

  const handleInvest = async () => {
    if (!address) {
      handleMissingWallet();
      return;
    }

    if (stablecoinAllowance < 1000000000000) {
      setInvestDisabled(true);
      await approve();
      setInvestDisabled(false);
      const value = await allowance(address);
      setStablecoinAllowance(value);
    } else if (investStablecoin > 0 && weeklyAmount > 0) {
      setInvestDisabled(true);
      await becomeUser(weeklyAmount, investStablecoin);
      setInvestDisabled(false);
      getUser(address).then((user) => {
        setUser(user);
      });
    }
  };

  const handleDivest = async () => {
    if ( divestStablecoin > 0 || divestEther > 0) { 
      if (!address) {
        handleMissingWallet();
        return;
      }
  
      if (parseFloat(divestEther) > parseFloat(user.ethBalance) || parseFloat(divestStablecoin) > parseFloat(user.stablecoinBalance)) {
        alert('You cannot divest more than you have');
        return;
      }
  
      setInvestDisabled(true);
      await withdrawStablecoinAndEther(divestStablecoin, divestEther);
      setInvestDisabled(false);
      getUser(address).then((user) => {
        setUser(user);
      });
    }
  };

  return (
    <div className='actions'>
      <Card>
        <CardContent>
          <Card className='padding-10' sx={{ minWidth: 275 }}>
            <Typography variant="h6">
                Current position
            </Typography>
            <Typography variant="body1" color={"text.secondary"}>
                <i>{`Your current balance is ${user.ethBalance || 0} ETH, ${user.stablecoinBalance || 0} USDC and your weekly swap amount is ${user.weeklyInvestment ? user.weeklyInvestment : "0.00"} USDC.`}</i>
            </Typography>
          </Card>

          <Card className='padding-10' sx={{ minWidth: 275, justifyContent:"center", flexDirection: "column", display:"flex" }}>
            <Typography variant="h6">
                Invest
            </Typography>
            <TextField
              onChange={(e) => setInvestStablecoin(e.target.value)}
              label="Please specify the amount of USDC you want to deposit"
            />
            <br />
            <TextField
              onChange={(e) => setWeeklyAmount(e.target.value)}
              value={weeklyAmount}
              label="Please specify the amount of USDC you want invest weekly"
            />
            <br />
            <div className='width-150-px'>
              <Button disabled={investDisabled} onClick={() => handleInvest()} fullWidth variant="outlined">
                {
                  investDisabled ? "Waiting.." : (
                    stablecoinAllowance < 10000000000000000 
                    ? 
                      "Approve" 
                    : 
                      "Deposit"
                  )
                }
              </Button>
            </div>
          </Card>

          <Card className='padding-10' sx={{ minWidth: 275, justifyContent:"center", flexDirection: "column", display:"flex" }}>
            <Typography variant="h6">
                Divest
            </Typography>
            <TextField
              label="Please specify the amount of USDC you want to withdraw"
              onChange={(e) => setDivestStablecoin(e.target.value)}
            />
            <br />
            <TextField
              onChange={(e) => setDivestEther(e.target.value)}
              label="Please specify the amount of Ether you want to withdraw"
            />
            <br />
            <div className='width-150-px'>
              <Button disabled={investDisabled} onClick={() => handleDivest()} fullWidth variant="outlined">
                {
                  investDisabled ? "Waiting.." : "Withdraw"
                }
              </Button>
            </div>
          </Card>
        </CardContent>
      </Card>
      <br />
    </div>
  );
}