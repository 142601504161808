// app component that implements 3 routes with react-router

import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Main from './pages/Main';
import Privacy from './pages/Privacy';
import Imprint from './pages/Imprint';

export default function App() {
  return (
    <Router>
      <div className='container'>
        <div className='layout'>
          <Routes>
            <Route exact path="/" element={<Main/>}/>
            <Route exact path="/privacy" element={<Privacy/>}/>
            <Route exact path="/imprint" element={<Imprint/>}/>
          </Routes>
        </div>
      </div>
    </Router>
  );
}