import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const preventDefault = (event) => event.preventDefault();

export default function Bottom() {
  return (
	<div className='header'>
		<Box onClick={preventDefault} sx={{ typography: 'body1',
  '& > :not(style) + :not(style)': {
    ml: 2,
  },flexGrow: 1 }}>
			<AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
				<Toolbar sx={{justifyContent: "center"}}>
          <Link to="/imprint" style={{ textDecoration: "none"}}>
            <Button>
              Imprint
            </Button>
          </Link>
          <div style={{width: 12}} />
          <Link to="/privacy" style={{ textDecoration: "none"}}>
            <Button>
              Privacy Policy
            </Button>
          </Link>
				</Toolbar>
			</AppBar>
		</Box>
	</div>
  );
}

