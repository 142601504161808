import ReactDOM from 'react-dom/client';
import React from 'react';

import './index.css';
import App from './App';

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import "@rainbow-me/rainbowkit/styles.css";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const { chains, provider } = configureChains(
    [chain.goerli],
    [publicProvider()]
);

const { connectors } = getDefaultWallets({
    appName: "Fifty Finance",
    chains
});

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
	   <WagmiConfig client={wagmiClient}>
			<RainbowKitProvider chains={chains}>
				<ThemeProvider theme={darkTheme}>
					<App />
				</ThemeProvider>
			</RainbowKitProvider>
		</WagmiConfig>
  </React.StrictMode>
);