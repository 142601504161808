import { ethers } from "ethers";
import DCAabi from "./DCAabi.json";
import ERC20abi from "./ERC20abi.json";

let provider;
let signer; 
if (window.ethereum) {
 provider = new ethers.providers.Web3Provider(window.ethereum);
 signer = provider.getSigner();
}

const contract_dca = new ethers.Contract("0xA784dB96784C6341a12F19b3868fEAd8A0aB6BDc", DCAabi, signer);
const contract_usdc = new ethers.Contract("0x07865c6e87b9f70255377e024ace6630c1eaa37f", ERC20abi, signer); // goerli

export const becomeUser = async (weeklyInvestment, amount) => {

  const amountWithDecimals = ethers.utils.parseUnits(amount, 6);
  const weeklyInvestmentWithDecimals = ethers.utils.parseUnits(weeklyInvestment, 6);

  const user = await getUser(signer._address);

  if (user.walletAddress !== "0x0000000000000000000000000000000000000000") {
    const tx = await contract_dca.updateUser(weeklyInvestmentWithDecimals.toString(), amountWithDecimals.toString(), {
      gasLimit: "1000000"
    });
    return tx.wait();
  } else {
    const tx = await contract_dca.becomeUser(weeklyInvestmentWithDecimals.toString(), amountWithDecimals.toString(), {
      gasLimit: "1000000"
    });
    return tx.wait();
  }
}

export const getUser = async (address) => {
  try {
    const user = await contract_dca.getUser(address);
    return {
      ethBalance: ethers.utils.formatEther(user.ethBalance.toString()),
      stablecoinBalance: ethers.utils.formatUnits(user.stablecoinBalance.toString(), 6),
      weeklyInvestment: ethers.utils.formatUnits(user.amountPerPeriod.toString(), 6),
    }
  } catch (error) {
    return {
      ethBalance: 0,
      stablecoinBalance: 0,
      weeklyInvestment: 0
    };
  }
}

export const withdrawStablecoinAndEther = async (amount, etherAmount) => {
  const amountWithDecimals = ethers.utils.parseUnits(amount, 6).toString();
  const etherAmountWithDecimals = ethers.utils.parseEther(etherAmount.toString()).toString();

  const tx = await contract_dca.withdrawStablecoinAndEther(amountWithDecimals, etherAmountWithDecimals, {
    gasLimit: "2000000"
  });
  return tx.wait();
}

export const approve = async () => {
  try {
    const tx = await contract_usdc.approve(contract_dca.address, "115792089237316195423570985008687907853269984665640564039457584007913129639935", {
      gasLimit: "2000000"
    });
    return tx.wait();
  } catch (error) {
    console.log(error);
  }
}

export const allowance = async (address) => {
  const allowance = await contract_usdc.allowance(address, contract_dca.address);
  return allowance;
}
