import Bottom from '../components/bottom';
import Connect from '../components/connect';

export default function Imprint() {
  return (
    <div className='imprint'>
      <Connect />
      <div className='content'>
      IMPRESSUM<br /><br />
      4breakfast GmbH <br /> Marie-Curie-Str. 1 <br /> 26129 Oldenburg<br /><br />

      +49 (0) 1789740832<br />

      moin@sergen.io<br />

      www.sergen.io<br /><br />

      Vertretungsberechtigter Geschäftsführer: <br />

      Sergen Sentürk<br />

      USt-IdNr. DE 343217378<br /><br />

      

      Registernummer: HRB 216855<br />

      Registergericht: Amtsgericht Oldenburg<br />
      </div>
      <Bottom />
  </div>
  );
}
