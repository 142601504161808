import Bottom from '../components/bottom';
import FAQ from '../components/faq';
import Connect from '../components/connect';
import Actions from '../components/actions';
import Infobar from '../components/infobar';

export default function Main() {
  return (
	<div className='App'>
    <Connect />
    <Infobar />
    <Actions />
    <FAQ />
    <Bottom />
	</div>
  );
}


