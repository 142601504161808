import * as React from 'react';
import Card from '@mui/material/Card';
import { Typography } from '@mui/material';
import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
`;

const PaddedCard = styled(Card)`
  padding: 10px;
  min-width: 275px;
`;

export default function Infobar() {
  return (
    <Header>
      <PaddedCard>
        <Typography textAlign="center" variant="h2">
          <b>Fifty</b> Finance
        </Typography>
        <br />
        <Typography textAlign="center" variant="body1">
          Buy Ether weekly for 0.50 USDC
        </Typography>
        <Typography textAlign="center" variant="body2">
          <b>On-chain DCA</b>
        </Typography>
      </PaddedCard>
      <br />
    </Header>
  );
}
