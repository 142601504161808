import { ConnectButton } from "@rainbow-me/rainbowkit";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function Connect() {
  return (
	<div className='connect'>
			<AppBar position="static">
				<Toolbar>
          <Typography sx={{ flexGrow: 1 }}></Typography>
					<ConnectButton />
				</Toolbar>
			</AppBar>
    <br />
	</div>
  );
}


