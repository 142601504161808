import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardContent from '@mui/material/CardContent';

export default function FAQ() {
  return (
    <div className="faq">
      <Card>
        <CardContent>
          <br />
          <Typography variant='body1' color="text.secondary">
            Frequently Asked Questions
          </Typography>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What is this?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Fifty Finance is the first on-chain DCA tool on the Ethereum mainnet. 
                Users can deposit an amount of USDC and choose how much of it should be invested into Ether weekly. 
                The contract will go through the list of all users once a week and create a swap transaction on Uniswap V3. 
                ChainLink Upkeeps are used as the weekly trigger for the swap.
                For this service users pay 0.50 USDC fees per swap. The users can withdraw Ether or stablecoins whenever they want.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Who built this?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Fifty Financy is currently a WIP built by me, Sergen. I am a software engineer from the so called Crypto Valley Zug, Switzerland. 
                I like to build prototypes of things that are useful and easy to use. 
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>What are the next steps?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                More stablecoins, more assets to invest in. A better UI. The smart contract needs to be much more gas efficient. 
                There are functions missing like the ability to change the weekly investment amount.
                Automatically swapping the collected fees into Ether to fund the contract.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </div>
  );
}